<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <v-card ref="tipsCard" class="rounded-lg pa-6 fill-height">
    <v-row no-gutters justify="center" class="fill-height">
      <v-col
        :cols="isSingleColumnLayout ? 12 : 5"
        :class="{
          'pr-4': !isSingleColumnLayout,
          'pb-4': isSingleColumnLayout
        }"
      >
        <v-skeleton-loader
          v-if="isLoading"
          type="image"
          width="100%"
          height="200"
        />
        <v-img
          v-else
          contain
          :src="tipOfTheDay.thumbnail"
          max-height="220"
          class="rounded-lg"
        />
      </v-col>
      <v-col :cols="isSingleColumnLayout ? 12 : 7">
        <div v-if="isLoading">
          <v-skeleton-loader type="heading" class="mb-4" />
          <v-skeleton-loader type="paragraph" />
        </div>

        <v-row
          v-else
          no-gutters
          class="fill-height"
          :class="{
            'text-center': isSingleColumnLayout
          }"
        >
          <v-col cols="12">
            <v-row
              no-gutters
              align="center"
              :justify="isSingleColumnLayout ? 'center' : null"
            >
              <v-icon class="mr-2" small color="yellow darken-3">
                mdi-lightbulb-on-outline
              </v-icon>
              <div
                class="text-overline text--secondary"
                style="
                  color: var(--v-primary-font-color-base);
                  line-height: 1.5;
                "
              >
                Tip of the Day
              </div>
            </v-row>
            <div
              class="text-h6"
              style="color: var(--v-primary-font-color-base)"
            >
              {{ tipOfTheDay.title }}
            </div>
            <div
              class="text-body-2 mt-2 mx-auto"
              style="color: var(--v-secondary-font-color-base)"
              v-html="tipOfTheDay.description"
            />
          </v-col>
          <v-col
            cols="12"
            align-self="end"
            class="mt-8"
            :class="{
              'text-center': isSingleColumnLayout
            }"
          >
            <v-row
              no-gutters
              align="center"
              :justify="isSingleColumnLayout ? 'center' : undefined"
            >
              <v-col
                cols="auto"
                class="text-body-2"
                style="color: var(--v-primary-font-color-base)"
              >
                Did you find this helpful?
              </v-col>
              <v-col cols="auto" class="ml-2">
                <v-btn icon small color="green" @click="upvoteTip">
                  <v-icon small>mdi-thumb-up-outline</v-icon>
                </v-btn>
                <v-btn icon small color="red" @click="downvoteTip">
                  <v-icon small>mdi-thumb-down-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'

import {
  getTipsOfTheDay,
  downTipsOfTheDay,
  upvoteTipsOfTheDay
} from '@/services/TipsService'
import { voteType } from '@/utils/analytics/abstraction/eventEnums'

export default {
  name: 'TipsOfTheDayCard',

  data() {
    return {
      tipOfTheDay: null,
      isLoading: true,
      isSingleColumnLayout: false,
      cardSizeObserver: new ResizeObserver(this.onSizeChange)
    }
  },

  mounted() {
    this.cardSizeObserver.observe(this.$refs.tipsCard.$el)
    this.fetchTipOfTheDay()
  },

  destroyed() {
    this.cardSizeObserver.disconnect()
  },

  methods: {
    ...mapMutations(['updateNotificationPayload']),
    async fetchTipOfTheDay() {
      this.isLoading = true
      try {
        const res = await getTipsOfTheDay({ client: 'web' })
        this.tipOfTheDay = res.data
      } catch (error) {
        this.updateNotificationPayload({
          message: 'Unable to retrieve tip of the day',
          code: 400,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    upvoteTip() {
      try {
        upvoteTipsOfTheDay(this.tipOfTheDay.id)
        this.updateNotificationPayload({
          message: 'Thanks for your input. Glad to know you found it useful.',
          code: 201,
          timeout: 3000
        })

        this.$analytics.trackTipVote(
          voteType.UPVOTE,
          this.tipOfTheDay.id,
          this.tipOfTheDay.title
        )
      } catch (error) {
        this.updateNotificationPayload({
          message: 'Oops, unable to record your input!',
          code: 400,
          timeout: 3000
        })
      }
    },
    downvoteTip() {
      try {
        downTipsOfTheDay(this.tipOfTheDay.id)
        this.updateNotificationPayload({
          message: 'Thanks for your input. We will improve it.',
          code: 201,
          timeout: 3000
        })
        this.$analytics.trackTipVote(
          voteType.DOWNVOTE,
          this.tipOfTheDay.id,
          this.tipOfTheDay.title
        )
      } catch (error) {
        this.updateNotificationPayload({
          message: 'Oops, unable to record your input!',
          code: 400,
          timeout: 3000
        })
      }
    },
    onSizeChange(entries) {
      requestAnimationFrame(() => {
        this.isSingleColumnLayout = entries[0].target.clientWidth < 600
      })
    }
  }
}
</script>
