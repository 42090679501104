// Copyright (C) 2023-2024 Skylark Drones

import routesName from '@/router/routesName'

/**
 * Represent an event object upon which an action could be performed. This is part of
 * event name which follows the format of "Object Action".
 * Must follow proper case.  Words are separated by spaces.
 *
 */
export const eventObject = {
  WALKTHROUGH: 'Walkthrough',
  SIGNUP: 'Signup',
  USER: 'User',
  REGISTER: 'Register',
  DIALOG: 'Dialog',
  SITE: 'Site',
  MISSION: 'Mission',
  GCP: 'Gcp',
  COLLECTION: 'Collection',
  STREAM: 'Stream',
  STREAM_AUDIO: 'Stream Audio',
  STREAM_VISIBILITY: 'Stream Visibility',
  STREAM_OBJECT_ANNOTATION: 'Stream Object Annotation',
  STREAM_OBJECT_DETECTION: 'Stream Object Detection',
  MAP_LAYER: 'Map Layer',
  ERROR: 'Error',
  DRONE: 'Drone',
  PERMISSION: 'Permission',
  SITE_TYPE: 'Site Type',
  MISSION_TYPE: 'Mission Type',
  SITE_LOCATION: 'Site Location',
  FLEET: 'Fleet',
  SITES: 'Sites',
  SITES_COLLECTIONS: 'Sites Collections',
  ORGANIZATION: 'Organization',
  MEMBER: 'Member',
  GUEST: 'Guest',
  INVITE: 'Invite',
  ROLE: 'Role',
  NEWS_LIST: 'News List',
  DMO_INSTALLER: 'DMO Installer',
  SUPPORTED_DRONE_DETAIL: 'Supported Drone Detail',
  AIRSPACE_ADVISORY: 'Airspace Advisory',
  TIP: 'Tip',
  HOMEPAGE: 'Homepage',
  DATA_VERIFICATION: 'Data Verification',
  IMAGE_METADATA: 'Image Metadata',
  IMAGE_DATASET: 'Image Dataset',
  VERIFICATION_GCP: 'Verification GCP',
  MANUAL_GCP_VERIFICATION: 'Manual GCP Verification',
  VERIFICATION_MISSION: 'Verification Mission'
}

/**
 * Represent an action which could be performed on an object. This is part of
 * event name which follows the format of "Object Action".
 * Must follow proper case. Words are separated by spaces.
 *
 */
export const eventAction = {
  VIEW: 'View',
  CLICK: 'Click',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  SIGNUP: 'Signup',
  SKIP: 'Skip',
  DISMISS: 'Dismiss',
  CONNECT: 'Connect',
  DISCONNECT: 'Disconnect',
  JOIN: 'Join',
  END: 'End',
  GRANTED: 'Granted',
  DENIED: 'Denied',
  SHARE: 'Share',
  DRAW: 'Draw',
  UPLOAD: 'Upload',
  SELECT: 'Select',
  CONFIRM: 'Confirm',
  CREATE: 'Create',
  REFRESH: 'Refresh',
  DELETE: 'Delete',
  UPDATE: 'Update',
  EXPORT: 'Export',
  BULK_DELETE: 'Bulk Delete',
  ADD_SITES: 'Add Sites',
  REVOKE: 'Revoke',
  LEAVE: 'Leave',
  SEARCH: 'Search',
  TOGGLE: 'Toggle',
  PLAN: 'Plan',
  PERMISSION: 'Permission',
  VIEW_MODE: 'View Mode',
  GROUP: 'Group',
  FILTER: 'Filter',
  FEATURES: 'Features',
  ACCEPT: 'Accept',
  SWITCH: 'Switch',
  DECLINE: 'Decline',
  SORT: 'Sort',
  REMOVE: 'Remove',
  EDIT: 'Edit',
  DOWNLOAD: 'Download',
  VOTE: 'Vote',
  SCROLL: 'Scroll',
  EXECUTE: 'Execute',
  COMPLETE: 'Complete',
  INVALID: 'Invalid',
  BULK_SELECT: 'Bulk Select',
  SPLIT: 'Split'
}

/**
 * Represent properties which are used to add extra information to the event.
 * Must follow lower snake case. Words are separated by underscores.
 *
 */
export const eventProperties = {
  PLATFORM: 'platform',
  USER_NAME: '$name',
  USER_EMAIL: '$email',
  USER_COMPANY: 'company',
  SOURCE: 'source',
  NAME: 'name',
  IS_LOGIN: 'is_login',
  ERROR_TYPE: 'error_type',
  ERROR_MESSAGE: 'error_message',
  IS_PUBLIC: 'is_public',
  ID: 'id',
  TITLE: 'title',
  URL: 'url',
  IS_FEATURED: 'is_featured',
  TYPE: 'type',
  ELEVATION_SOURCE: 'elevation_source',
  SIZE: 'size',
  HAS_MISSIONS: 'has_missions',
  IS_AUTOGENERATE: 'is_autogenerate',
  VIEW_TYPE: 'view_type',
  SITE_TYPE: 'site_type',
  MISSION_TYPE: 'mission_type',
  MISSION_PARAMS: 'mission_params',
  DID_FAIL: 'did_fail',
  STYLE: 'style',
  SITES_COUNT: 'sites_count',
  LOCATION: 'location',
  IS_SAMPLE: 'is_sample',
  IS_ENABLE: 'is_enable',
  GCP_FORMAT: 'gcp_format',
  SITE_FORMAT: 'site_format',
  MISSION_FORMAT: 'mission_format',
  MISSION_EXPORT_DESTINATION: 'mission_export_destination',
  OLD_ACCESS_LEVEL: 'old_access_level',
  NEW_ACCESS_LEVEL: 'new_access_level',
  IS_GUEST: 'is_guest',
  IS_NEW_USER: 'is_new_user',
  VIEW_MODE: 'view_mode',
  GROUP_TYPE: 'group_type',
  CREATED_BY_FILTER_COUNT: 'created_by_filter_count',
  FLOWN_BY_FILTER_COUNT: 'flown_by_filter_count',
  CREATED_BETWEEN_FILTER: 'created_between_filter',
  STATUS_FILTER: 'status_filter',
  SEARCH_STRING: 'search_string',
  MAP_FEATURE_TYPE: 'map_feature_type',
  STATUS: 'status',
  SELF_ROLE: 'self_role',
  SORT_ORDER: 'sort_order',
  SORT_TYPE: 'sort_type',
  ROLE: 'role',
  ORG_MEMBERS_COUNT: 'org_members_count',
  IS_PERSONAL_ORG: 'is_personal_org',
  ANNOTATION_CLASS: 'annotation_class',
  SPACING: 'spacing',
  CORRIDOR_WIDTH: 'corridor_width',
  DRONE_MODEL: 'drone_model',
  VOTE_TYPE: 'vote_type',
  IS_MY_SITES: 'is_my_sites',
  DIRECTION: 'direction',
  IS_VIEW_ALL: 'is_view_all',
  STEP: 'step',
  IMAGE_COUNT: 'image_count',
  MISSION_COUNT: 'mission_count',
  TASK_ID: 'task_id',
  CHECKS_ENABLED: 'checks_enabled',
  CUSTOM_CAMERA_MODEL: 'custom_camera_model',
  CUSTOM_FOCAL_LENGTH: 'custom_focal_length',
  CRITICAL_FAILURE_COUNT: 'critical_failure_count',
  MEDIUM_FAILURE_COUNT: 'medium_failure_count',
  IS_GCP_VISIBLE: 'is_gcp_visible',
  PRODUCT_BRAND: 'product_brand',
  IS_SELECTED: 'is_selected',
  SITE_ID: 'site_id',
  SITE_SIZE: 'site_size'
}

/**
 * Represents a page, used to track page views. Since it as object it must follow same
 * naming conventions as [EventObject].
 *
 */
export const page = routesName

/**
 * Represents source of login event.
 *
 */
export const loginSource = {
  HOME: page.HomePage
}

/**
 * Represents a dialog, used to track dialog views. Since it as object it must follow same
 * naming conventions as [EventObject].
 *
 */
export const dialog = {
  GOOGLE_LOGIN: 'Google Login Prompt',
  SIGNUP: 'Signup',

  STREAM_OFFLINE: 'Stream Offline Warning',

  SITE_UPLOAD_MULTI_FEATURE: 'Site Upload Multi-feature Warning',
  SITE_UPLOAD_INVALID_KML: 'Site Upload Invalid Warning',
  SITE_TYPE_SELECT: 'Site Type Selection',
  SITE_SHARE: 'Site Share Selection',
  SITE_DELETE_CONFIRMATION: 'Site Delete Confirmation',
  SITE_LOCATION_SEARCH: 'Site Location Search',

  MISSION_TYPE_SELECT: 'Mission Type Selection',
  MISSION_PREVIEW: 'Mission Preview',
  MISSION_ABORT_CONFIRMATION: 'Mission Abort Confirmation',
  MISSION_BULK_DELETE_CONFIRMATION: 'Mission Bulk Delete Confirmation',

  MAP_LAYER_SELECTION: 'Map Layer Selection',

  COLLECTION_CREATION: 'Collection Creation',
  COLLECTION_ADDITION: 'Collection Addition',
  COLLECTION_ADD_SITES: 'Collection Add Sites',
  COLLECTION_UPDATION: 'Collection Update',
  COLLECTION_DELETE_CONFIRMATION: 'Collection Delete Confirmation',

  SETTINGS: 'Settings',

  NEWS: 'News'
}

/**
 * Represents type of errors.
 *
 */
export const errorType = {
  GOOGLE_AUTH: 'Google Authentication'
}

export const voteType = {
  UPVOTE: 'upvote',
  DOWNVOTE: 'downvote'
}

/**
 * Represents permissions that can be requested.
 *
 */
export const permission = {
  MICROPHONE: 'Microphone'
}

/**
 * Represents a source where permission can be requested from.
 *
 */
export const permissionSource = {
  STREAM: 'Stream',
  SITE_DETAILS: 'Site Details'
}

/**
 * Represents different view types.
 *
 */
export const ViewType = {
  DIALOG: 'Dialog'
}

export const SiteType = {
  area: 'polygon',
  corridor: 'lineString',
  asset: 'point'
}

export class MissionType {
  static MAPPING = 'mapping'
  static VIDEO = 'video'
  static WAYPOINTS = 'waypoints'
  static 'CELL-TOWER' = 'cell_tower'
  static 'WIND-TURBINE' = 'wind_turbine'
  static POI = 'poi'
  static UNKNOWN = 'unknown'

  static from(missionType) {
    return this[missionType.toUpperCase()]
  }

  static fromDetailedMissionType(detailedMissionType) {
    switch (detailedMissionType) {
      case DetailedMissionType.AREA_MAPPING:
      case DetailedMissionType.CORRIDOR_MAPPING:
        return this.MAPPING
      case DetailedMissionType.CORRIDOR_VIDEO:
        return this.VIDEO
      case DetailedMissionType.WAYPOINTS:
        return this.WAYPOINTS
      case DetailedMissionType.POI:
        return this.POI
      case DetailedMissionType.NOT_SUPPORTED:
      default:
        return this.UNKNOWN
    }
  }
}

export class DetailedMissionType {
  static AREA_MAPPING = 'area_mapping'
  static CORRIDOR_MAPPING = 'corridor_mapping'
  static CORRIDOR_VIDEO = 'corridor_video'
  static WAYPOINTS = 'waypoints'
  static POI = 'poi'
  static NOT_SUPPORTED = ''

  static from(siteType, missionType) {
    if (siteType === SiteType.area && missionType === MissionType.MAPPING) {
      return this.AREA_MAPPING
    } else if (
      siteType === SiteType.corridor &&
      missionType === MissionType.MAPPING
    ) {
      return this.CORRIDOR_MAPPING
    } else if (
      siteType === SiteType.corridor &&
      missionType === MissionType.VIDEO
    ) {
      return this.CORRIDOR_VIDEO
    } else if (missionType === MissionType.WAYPOINTS) {
      return this.WAYPOINTS
    } else if (missionType === MissionType.POI) {
      return this.POI
    } else {
      return this.NOT_SUPPORTED
    }
  }
}

export const ExportFormat = {
  CSV: 'csv',
  KML: 'kml',
  WAYPOINTS: 'waypoints'
}

export const ExportDestination = {
  LITCHI: 'litchi',
  MISSION_PLANNER: 'mission_planner',
  GOOGLE_EARTH: 'google_earth'
}

export const MapStyles = {
  map: 'default',
  satellite: 'satellite',
  hybrid: 'hybrid'
}

export const FleetType = {
  DRONE: 'Drone',
  BATTERY: 'Battery'
}

export const source = {
  // click
  ORG_MEMBERS_TABLE_ROW: 'org_member_table_row',
  INC_PROFILE_ALERT: 'inc_profile_alert',
  FAILED_IMAGE_THUMBNAIL: 'failed_image_thumbnail',
  MANUAL_GCP_VERIFICATION_BTN: 'manual_verification_btn',
  MAP_FEATURE: 'map_feature',

  // page
  SITE_DETAILS_PAGE: 'site_details_page',
  ORG_GUESTS_PAGE: 'org_guests_page',

  // menu
  SITE_CARD_MENU: 'site_card_menu',
  USER_MENU: 'user_menu',
  ORG_MEMBERS_TABLE_ROW_MENU: 'org_members_table_row_menu',
  MAP_CONTEXT_MENU: 'map_context_menu',
  MISSION_SELECTION_MENU: 'mission_selection_menu',

  // card
  MISSION_DETAILS_SIDEBAR: 'mission_details_sidebar',
  DATA_CHECK_CARD: 'data_check_card',

  // toolbar
  SITE_DETAILS_TOOLBAR: 'site_details_toolbar',

  // dialog
  MISSION_GENERATION_DIALOG: 'mission_generation_dialog'
}

export const mapFeatureType = {
  site: 'site',
  mission: 'mission',
  gcp: 'gcp',
  utm: 'utm'
}

export const userRole = {
  admin: 'admin',
  owner: 'owner',
  member: 'member'
}

export const sortOrder = {
  ASC: 'asc',
  DESC: 'desc'
}

export const orgMemberSortType = {
  name: 'name',
  role: 'role',
  joining_date: 'date_added',
  last_seen: 'last_active_date'
}
